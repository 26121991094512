import React, { useRef, useEffect, useState } from "react";
import Lottie from "lottie-react";
import animationData from "app/assets/images/loading/loadingMain.json";

const LoadingAnimation = () => {
  const lottieRef = useRef(null);
  const [availableHeight, setAvailableHeight] = useState(
    window.innerHeight * 0.8
  );

  useEffect(() => {
    const handleResize = () => {
      setAvailableHeight(window.innerHeight * 0.8);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ width: "100%", height: `${availableHeight}px` }}>
      <Lottie
        lottieRef={lottieRef}
        animationData={animationData}
        loop={true}
        autoplay={true}
        rendererSettings={{
          preserveAspectRatio: "xMidYMid meet",
        }}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};

export default LoadingAnimation;
