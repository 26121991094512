import { getAuth, onAuthStateChanged } from "firebase/auth";
import React, { createContext, useContext, useState, useEffect } from "react";

// Create the context
const ChatContext = createContext();

// Provide the context to your app
export const ChatProvider = ({ children }) => {
  const [selectedContact, setSelectedContact] = useState(null);
  const [recentChats, setRecentChats] = useState([]);
  const [currentUserUid, setCurrentUserUid] = useState(null); // Store current user's UID
  const [activePanel, setActivePanel] = useState(0);

  const auth = getAuth();

  useEffect(() => {
    // Retrieve UID from local storage if available
    const storedUid = localStorage.getItem("userUid");
    if (storedUid) {
      setCurrentUserUid(storedUid);
      // console.log("UID retrieved from local storage:", storedUid);
    }

    // Listener for auth state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUserUid(user.uid); // Set the UID when the user is logged in
        // console.log("Authenticated user UID:", user.uid);

        // Store the UID in local storage for future use
        localStorage.setItem("userUid", user.uid);
      } else {
        setCurrentUserUid(null); // Reset UID when no user is logged in
        console.log("No user is authenticated.");
        localStorage.removeItem("userUid"); // Clear local storage if not logged in
      }
    });

    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, [auth]);

  const setPanel = (panelIndex) => {
    setActivePanel(panelIndex);
  };

  // Function to set the current user's UID manually
  const setUserUid = (uid) => {
    if (uid) {
      setCurrentUserUid(uid);
      localStorage.setItem("userUid", uid); // Store the UID in local storage
    } else if (auth.currentUser) {
      setCurrentUserUid(auth.currentUser.uid);
      localStorage.setItem("userUid", auth.currentUser.uid); // Store the UID in local storage
    } else {
      console.error("No valid UID available to set.");
    }
  };

  // Function to select a contact and update recent chats
  const selectContact = (contact) => {
    setSelectedContact(contact);

    // Update recent chats if the contact isn't already present
    if (!recentChats.some((chat) => chat.id === contact.id)) {
      setRecentChats((prev) => [...prev, contact]);
    }
  };

  // Function to remove a chat from recent chats
  const removeRecentChat = (contactId) => {
    setRecentChats((prev) => prev.filter((chat) => chat.id !== contactId));
  };

  return (
    <ChatContext.Provider
      value={{
        selectedContact,
        recentChats,
        selectContact,
        removeRecentChat,
        setUserUid,
        currentUserUid,
        activePanel,
        setPanel,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

// Custom hook to use the chat context
export const useChatContext = () => useContext(ChatContext);
