import React from "react";
import RoutesMain from "app/routes/Routes";
import "app/assets/css/reset.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChatProvider } from "./context/ChatContext";

const App = () => {
  return (
    <>
      <ChatProvider>
        <RoutesMain />
        <ToastContainer />
      </ChatProvider>
    </>
  );
};

export default App;
