import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute"; // Adjust the import path
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Add toast styles
import LoadingAnimation from "app/components/shared/LoadingAnimation";

const Welcome = lazy(() => import("app/containers/welcomePage/Welcome"));
const Login = lazy(() => import("app/containers/auth/Login"));
const SignUp = lazy(() => import("app/containers/auth/SignUp"));
const ForgotPassword = lazy(() => import("app/containers/auth/ForgotPassword"));
const Dashboard = lazy(() => import("app/containers/home/Dashboard"));

const RoutesMain = () => {
  return (
    <Router>
      <ToastContainer /> {/* Add Toast container for notifications */}
      <Routes>
        {/* Public Routes */}
        <Route
          element={<ProtectedRoute />} // Protecting all routes
        >
          <Route
            path="/"
            element={
              <Suspense fallback={<LoadingAnimation />}>
                <Welcome />
              </Suspense>
            }
          />
          <Route
            path="/login"
            element={
              <Suspense fallback={<LoadingAnimation />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path="/signUp"
            element={
              <Suspense fallback={<LoadingAnimation />}>
                <SignUp />
              </Suspense>
            }
          />
          <Route
            path="/reset"
            element={
              <Suspense fallback={<LoadingAnimation />}>
                <ForgotPassword />
              </Suspense>
            }
          />
          <Route
            path="/dashboard"
            element={
              <Suspense fallback={<LoadingAnimation />}>
                <Dashboard />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default RoutesMain;
