import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "app/firebase/index";
// import { toast } from "react-toastify";

const ProtectedRoute = ({ redirectPath = "/login" }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const location = useLocation(); // To get the current path

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });

    return () => unsubscribe();
  }, []);

  if (isAuthenticated === null) {
    // While auth state is being checked, render nothing or a spinner
    return null;
  }

  if (isAuthenticated) {
    if (location.pathname !== "/dashboard") {
      // toast.info("Logout to access this route.");
      return <Navigate to="/dashboard" />;
    }
    return <Outlet />; // User is authenticated and already on /dashboard
  } else {
    if (location.pathname === "/dashboard") {
      return <Navigate to={redirectPath} />;
    }
    return <Outlet />; // User is not authenticated, render the route content
  }
};

export default ProtectedRoute;
